<template>
  <div>
    <div class="mb-2">
      <b-button-group>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          v-bind:class="[itemType == 'all' ? 'active' : '']"
          v-on:click="changeTableType('all')"
        >
          All Items
        </b-button>
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          v-bind:class="[itemType == 'sell' ? 'active' : '']"
          v-on:click="changeTableType('sell')"
        >
          Sell Items
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          v-bind:class="[itemType == 'refurbish' ? 'active' : '']"
          v-on:click="changeTableType('refurbish')"
        >
          Refurbish Item
        </b-button> -->
      </b-button-group>
      &nbsp;
      <!-- <b-button
        v-if="$store.getters['user/getUserDetails'].user_type_name === 'admin'"
        @click="syncItemsWithWooComm"
      >
        Sync Items with WooCommerce
      </b-button> -->
    </div>

    <all-item-list v-if="itemType == 'all'" />
    <!-- <sell-item-list v-if="itemType == 'sell'" />
    <refurbish-item-list v-if="itemType == 'refurbish'" /> -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardText,
  BButtonGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AllItemList from "./AllItemList.vue";
import SellItemList from "./SellItemList.vue";
import RefurbishItemList from "./RefurbishItemList.vue";
import ProductServices from "@/apiServices/ProductServices";

export default {
  components: {
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BButtonGroup,
    BPagination,
    AllItemList,
    SellItemList,
    RefurbishItemList,
  },
  directives: {
    Ripple,
  },
  setup() {},
  data() {
    return {
      itemType: "all",
    };
  },
  computed: {},
  methods: {
    async syncItemsWithWooComm() {
      try {
        const response = await ProductServices.syncProductsWithWoocom();
        console.log(response.data);
        if (response.data.status) {
        }
      } catch (err) {
        console.log("Error in g  ", err);
      }
    },
    changeTableType(itemType) {
      this.itemType = itemType;
    },
  },
  beforeMount() {
    // const userDetails = localStorage.getItem("userInfo");
    // console.log(userDetails);
  },
};
</script>

<style lang="scss" scoped>
.uppercase {
  text-transform: uppercase !important;
}

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
</style>
